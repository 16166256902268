import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import Title from "./Title";
import axios from "axios";
import { BuildOutlined, Iron } from "@mui/icons-material";
import { Link, ListItemSecondaryAction } from "@mui/material";
import StatsItem from "./modals/statsItem";

function createData(id, name, vendor, vendor_location, status, last_check) {
  return { id, name, vendor, vendor_location, status, last_check };
}

function preventDefault(event) {
  event.preventDefault();
}

export default function ItemList(props) {
  const handleClose = async () => {
    setaddItemOpen(false);
    setDeleteItemOpen(false);
    setStatsItemOpen(false);
  };
  const handleAddItemOpen = async () => {
    setaddItemOpen(true);
  };
  const handleDeleteItemOpen = async (event, id) => {
    setItemPicked(id);
    setDeleteItemOpen(true);
  };
  const handleAddItem = async () => {
    setaddItemOpen(false);
    await axios.post(
      `${props.baseUrl}/item/${userVendor}/${userSku}/${userLocation}/${userName}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      }
    );
    setItems([]);
  };
  const handleUserSku = async (event) => {
    setUserSku(event.target.value);
  };
  const handleUserVendor = async (event) => {
    setUserVendor(event.target.value);
  };
  const handleUserName = async (event) => {
    setUserName(event.target.value);
  };
  const handleUserLocation = async (event) => {
    setUserLocation(event.target.value);
  };
  const handleStatsItemOpen = async (event, id) => {
    setItemPicked(id);
    setStatsItemOpen(true);
  };

  const deleteItem = async () => {
    const response = await axios.delete(
      `${props.baseUrl}/items/${itemPicked}`,
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      }
    );
    setDeleteItemOpen(false);
    setItems([]);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const styleStats = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [items, setItems] = useState([]);
  const [userSku, setUserSku] = useState("");
  const [userName, setUserName] = useState("");
  const [userVendor, setUserVendor] = useState(-1);
  const [userLocation, setUserLocation] = useState("");
  const [addItemOpen, setaddItemOpen] = useState(false);
  const [showAddItem, setShowAddItem] = useState(true);
  const [deleteItemOpen, setDeleteItemOpen] = useState(false);
  const [statsItemOpen, setStatsItemOpen] = useState(false);
  const [itemPicked, setItemPicked] = useState(0);

  useEffect(async () => {
    const interval = setInterval(async () => {
      let rows = [];
      const response = await axios.get(`${props.baseUrl}/items/`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      for (let i = 0; i < response.data.length; i++) {
        const status = await axios.get(
          `${props.baseUrl}/status/${response.data[i].vendor_id}/${response.data[i].vendor_sku}/${response.data[i].vendor_location}`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          }
        );

        rows.push(
          createData(
            response.data[i].id,
            response.data[i].item_name,
            response.data[i].vendor,
            response.data[i].vendor_location,
            status.data.inStock ? "in Stock" : "Out of Stock",
            `${status.data.date}`
          )
        );
      }
      setItems(rows);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (items.length > 0) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return (
      <React.Fragment>
        <Modal
          open={deleteItemOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            Are you sure you want to delete this item?
            <Button onClick={deleteItem} variant="contained" color="warning">
              DELETE
            </Button>
          </Box>
        </Modal>
        <Modal
          open={statsItemOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleStats}>
            <StatsItem
              baseUrl={props.baseUrl}
              token={props.token}
              item={itemPicked}
            />
            <br />
            <Button onClick={handleClose} variant="contained" color="warning">
              Close
            </Button>
          </Box>
        </Modal>
        <Modal
          open={addItemOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleAddItem}>
              <TextField
                id="name"
                label="Item Name"
                variant="standard"
                value={userName}
                onChange={handleUserName}
              />
              <br />
              <br />
              <TextField
                id="aku"
                label="Item Number"
                variant="standard"
                value={userSku}
                onChange={handleUserSku}
              />
              <br />
              <br />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Vendor
                </InputLabel>
                <NativeSelect defaultValue={99} onChange={handleUserVendor}>
                  <option value={99}>Select</option>
                  <option value={0}>BestBuyCA</option>
                  <option value={2}>HomeDepotCA</option>
                  <option disabled={true} value={1}>
                    IkeaCA
                  </option>
                </NativeSelect>
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Location
                </InputLabel>
                <NativeSelect defaultValue="NA" onChange={handleUserLocation}>
                  <option value="NA">Select</option>
                  <option value="Online">Online</option>
                </NativeSelect>
              </FormControl>
              <br />
              <br />
              <Button type="submit" variant="contained" color="primary">
                Add Item
              </Button>
            </form>
          </Box>
        </Modal>
        <Title>Your Items</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Check</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow key={row.id}>
                <TableCell numeric component="a" href={row.url}>
                  {row.name}
                </TableCell>
                <TableCell>
                  <Typography color="white">{row.vendor}</Typography>
                </TableCell>
                <TableCell>
                  <Typography color="white">{row.vendor_location}</Typography>
                </TableCell>
                <TableCell>
                  <Typography color="white">
                    {row.status === "in Stock" ? "✅" : "❌"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="white">
                    {new Date(row.last_check)
                      .toLocaleString("en-US", options)
                      .replace(",", "")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {row.vendor === "HomedepotCA" ||
                      row.vendor === "amazonCA" ? (
                        <IconButton
                          onClick={(event) =>
                            handleStatsItemOpen(event, row.id)
                          }
                        >
                          <BarChartIcon />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <DoNotDisturbIcon />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        onClick={(event) =>
                          handleSettingsItemOpen(event, row.id)
                        }
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        onClick={(event) => handleDeleteItemOpen(event, row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {showAddItem ? (
          <Button disabled onClick={handleAddItemOpen}>
            Add Item
          </Button>
        ) : (
          <Button disabled>Limit reached/Account not approved</Button>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {showAddItem ? (
          <Button disabled onClick={handleAddItemOpen}>
            Add Item
          </Button>
        ) : (
          <Button disabled>Limit reached/Account not approved</Button>
        )}
      </React.Fragment>
    );
  }
}
