import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jwt_decode from "jwt-decode";
import { Alert } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function Login(props) {
  const [loginAttempt, setloginAttempt] = useState(0);
  const [returnData, setReturnData] = useState({});
  const handleSubmit = async (event) => {
    const data = new FormData(event.currentTarget);
    event.preventDefault();
    const payload = {
      email: data.get("email"),
      password: data.get("password"),
    };

    setloginAttempt(loginAttempt + 1);
    const response = await axios.post(`${props.baseUrl}/login/`, payload);

    let returnData = {
      auth: false,
      token: "",
      userData: {},
    };

    if (response.status === 200) {
      returnData.auth = true;
      returnData.token = response.data.token;
      returnData.userData = jwt_decode(response.data.token);
      setReturnData(returnData);
    }
    props.callbackData(returnData);
    return returnData;
  };
  const showAlert = () => {
    if (loginAttempt > 0) {
      return (
        <Alert severity="error">
          Incorrect username or password (Attempt #{loginAttempt})
        </Alert>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <HttpsOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {showAlert(loginAttempt)}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              theme={theme}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item></Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
