import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Title from "./Title";
import axios from "axios";

function preventDefault(event) {
  event.preventDefault();
}

export default function AccountInfo(props) {
  useEffect(() => {
    console.log("hello9090");
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [userApiKey, setUserApiKey] = useState(props.userData.apiKey);
  const [tempUserApiKey, setTempUserApiKey] = useState("");
  const [modalStatus, setModalStatus] = useState(false);

  const handleClose = async () => {
    setModalStatus(false);
  };
  const handleOpen = async () => {
    setModalStatus(true);
  };
  const handleUserApiKey = (event) => {
    setTempUserApiKey(event.target.value);
  };
  const handleForm = async () => {
    setModalStatus(false);
    console.log(props);
    const response = await axios.post(
      `${props.baseUrl}/user/apikey`,
      { apiKey: userApiKey },
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      }
    );
    setUserApiKey(tempUserApiKey);
  };
  const sendTest = async () => {
    await axios.post(`https://push.techulus.com/api/v1/notify/${userApiKey}?title=This_is_a_test&body=HelloWorld
    `);
  };
  return (
    <React.Fragment>
      <Title>Account Info</Title>
      <Typography color="text.secondary">
        Slot available: {props.userData.limit}
      </Typography>
      <Typography color="text.secondary">
        ApiKey: {userApiKey ? `${userApiKey}` : "Not Set"}
        <Button onClick={handleOpen}>Edit</Button>
        <Button onClick={sendTest}>Test</Button>
      </Typography>
      <Modal
        open={modalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleForm}>
            <TextField
              id="apikey"
              label="Your API Key"
              variant="standard"
              value={tempUserApiKey}
              onChange={handleUserApiKey}
              fullWidth
            />
            <br />
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Button type="submit" variant="contained" color="warning">
                  Edit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
