import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

let defaultChartData = {
  labels: [],
  datasets: [
    {
      label: "Price",
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgb(255, 99, 132)",
      data: [],
    },
  ],
};

export default function StatsItem(props) {
  //const [chartData, setChartData] = useState(defaultChartData);
  const [dataReady, setDataReady] = useState(false);
  const getStats = async () => {
    const response = await axios.get(`${props.baseUrl}/stats/${props.item}`, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    });
    let newLabels = [];
    let newData = [];
    for (let i = 0; i < response.data.length; i++) {
      newLabels.push(response.data[i].day);
      newData.push(parseInt(response.data[i].price.replace("$", "")));
    }
    defaultChartData.labels = newLabels;
    defaultChartData.datasets[0].data = newData;
    setDataReady(true);
    //setChartData(defaultChartData);
  };

  useEffect(() => {
    getStats();
  }, [dataReady]);

  if (dataReady) {
    return (
      <React.Fragment>
        <Typography color="text.secondary">Price History</Typography>
        <Line data={defaultChartData} />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Typography color="text.secondary">Loading Data</Typography>
      </React.Fragment>
    );
  }
}
