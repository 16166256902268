import React, { useState, useEffect } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import AccountInfo from "./AccountInfo";
import ItemList from "./ItemList";
import { Alarm } from "@mui/icons-material";
import { Alert } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://sebastienburon.com/">
        sebastien buron
      </Link>{" "}
      {"template by "}
      <Link color="inherit" href="https://mui.com/">
        mui
      </Link>
      {"."}
    </Typography>
  );
}

const mdTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function DashboardContent(props) {
  console.log("DashboardContent", props);
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* How to use application */}
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 300,
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    color="white"
                    gutterBottom
                  >
                    How to use
                  </Typography>
                  <Typography color="white">
                    1. Download the Push app{" "}
                    <Link href="https://itunes.apple.com/us/app/push-by-techulus/id1444391917?ls=1&mt=8">
                      IOS
                    </Link>{" "}
                    or{" "}
                    <Link href="https://play.google.com/store/apps/details?id=com.techulus.push">
                      Android
                    </Link>
                  </Typography>
                  <Typography color="white">
                    2. Set your API Key in the account info section
                  </Typography>
                  <Typography color="white">
                    3. Test your API Key you should get a HelloWorld from the
                    app
                  </Typography>
                  <Typography color="white">
                    4. Add your items to the app
                  </Typography>
                  <br />
                  <Typography color="white">Supported stores:</Typography>
                  <Typography color="white">Bestbuy (Online)</Typography>
                  <Typography color="white">
                    Ikea (Quebec City and Boucherville)
                  </Typography>
                  <Typography color="white">Home Depot (Online)</Typography>
                </Paper>
              </Grid>
              {/* User account information */}
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 300,
                  }}
                >
                  <AccountInfo
                    userData={props.userData}
                    token={props.token}
                    baseUrl={props.baseUrl}
                  />
                </Paper>
              </Grid>
              {/* Item list */}
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ItemList
                    userData={props.userData}
                    token={props.token}
                    baseUrl={props.baseUrl}
                  />
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard(props) {
  return (
    <DashboardContent
      token={props.loginData.token}
      userData={props.loginData.userData}
      baseUrl={props.baseUrl}
    />
  );
}
