import React, { useState, useEffect } from "react";
import Dashboard from "./dashboard/Dashboard";
import Login from "./login/login";

const BASEURL = "https://api.sebastienburon.com";
//const BASEURL = "http://localhost:4000";

export default function App() {
  const [loginData, setloginData] = useState({});
  const handleLogin = (data) => {
    console.log(data);
    setloginData(data);
  };
  if (loginData.auth) {
    return <Dashboard loginData={loginData} baseUrl={BASEURL} />;
  } else {
    return <Login callbackData={handleLogin} baseUrl={BASEURL} />;
  }
}
